import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-0 w-100 align-items-stretch" }
const _hoisted_2 = { class: "col col-xl-6 col-12" }
const _hoisted_3 = { class: "card h-100 mx-5" }
const _hoisted_4 = { class: "card-header card-header-jaya small" }
const _hoisted_5 = { class: "card-body border-top" }
const _hoisted_6 = { class: "col col-xl-6 col-12 mt-2" }
const _hoisted_7 = { class: "card h-100 mx-5" }
const _hoisted_8 = { class: "card-header card-header-jaya small" }
const _hoisted_9 = { class: "card-body border-top" }
const _hoisted_10 = { class: "text-gray-800" }
const _hoisted_11 = { class: "text-gray-800" }
const _hoisted_12 = { class: "text-gray-800" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "text-gray-800 d-flex justify-content-center" }
const _hoisted_15 = ["onClick", "disabled"]
const _hoisted_16 = { class: "text-gray-800" }
const _hoisted_17 = ["onClick", "disabled"]
const _hoisted_18 = { class: "text-gray-800" }
const _hoisted_19 = ["onClick", "disabled"]
const _hoisted_20 = { class: "text-gray-800 d-flex justify-content-center" }
const _hoisted_21 = ["onClick", "disabled"]
const _hoisted_22 = { class: "text-gray-800" }
const _hoisted_23 = { class: "text-gray-800" }
const _hoisted_24 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_title_jaya = _resolveComponent("card-title-jaya")!
  const _component_PrescriberContent = _resolveComponent("PrescriberContent")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_card_title_jaya, {
            "duotune-icon": "graphs/gra010",
            title: "Mon profil prescripeur",
            small: ""
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PrescriberContent, {
            mode: "display",
            prescriberId: _ctx.prescriber.prescriber_id
          }, null, 8, ["prescriberId"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_card_title_jaya, {
            "duotune-icon": "graphs/gra010",
            title: "Mes projets",
            small: ""
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.projects.length > 0)
            ? (_openBlock(), _createBlock(_component_Datatable, {
                "table-data": _ctx.projects,
                "table-header": _ctx.tableHeader,
                rowsPerPage: 10,
                key: _ctx.reloadTable
              }, {
                "cell-name": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(project.name), 1)
                ]),
                "cell-state": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getState(project.state)), 1)
                ]),
                "cell-edit": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("button", {
                      class: "btn btn-icon bi bi-pencil text-gray-800 fs-2 cursor-pointer",
                      onClick: ($event: any) => (_ctx.editProject(project.id))
                    }, null, 8, _hoisted_13)
                  ])
                ]),
                "cell-validate": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("button", {
                      class: "btn btn-icon bi bi-check-circle text-gray-800 fs-2 cursor-pointer",
                      onClick: ($event: any) => (_ctx.validateProject(project)),
                      disabled: project.state > 1
                    }, null, 8, _hoisted_15)
                  ])
                ]),
                "cell-invite": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("button", {
                      class: "btn btn-icon bi bi-envelope text-gray-800 fs-2 cursor-pointer",
                      onClick: ($event: any) => (_ctx.inviteToProject(project)),
                      disabled: project.state !== 3
                    }, null, 8, _hoisted_17)
                  ])
                ]),
                "cell-download": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("button", {
                      class: "btn btn-icon bi bi-download text-gray-800 fs-2 cursor-pointer",
                      onClick: ($event: any) => (_ctx.downloadProject(project)),
                      disabled: project.state !== 3
                    }, null, 8, _hoisted_19)
                  ])
                ]),
                "cell-delete": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("button", {
                      class: "btn btn-icon bi bi-trash text-gray-800 fs-2 cursor-pointer",
                      onClick: ($event: any) => (_ctx.deleteProject(project)),
                      disabled: project.state === 2
                    }, null, 8, _hoisted_21)
                  ])
                ]),
                "cell-users": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(project.users_count), 1)
                ]),
                "cell-diagnostics": _withCtx(({ row: project }) => [
                  _createElementVNode("div", _hoisted_23, _toDisplayString(project.diagnostics_count), 1)
                ]),
                _: 1
              }, 8, ["table-data", "table-header"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_24, "Aucun projet")),
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'new-project' })))
          }, " Nouveau projet ")
        ])
      ])
    ])
  ]))
}