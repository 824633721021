
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import PrescriberContent from "@/components/prescribers/PrescriberContent.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { computed, defineComponent, onMounted, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { notify, openLinkAgriBest } from "@/core/helpers/globalJaya";
import { PrescriberProjectPurposes } from "@/store/modules/PrescriberModule";

export default defineComponent({
  name: "prescriber-back-office",
  components: {
    CardTitleJaya,
    Datatable,
    PrescriberContent,
  },
  setup: function () {
    const store = useStore();
    const router = useRouter();
    let projects = computed(() => store.getters.getPrescriberProjects);
    let prescriber = computed(() => store.getters.currentUser);
    let projectPurposes = computed(
      () => store.getters.getPrescriberProjectPurposes
    );
    const reloadTable = ref<number>(1);

    const getState = (state) => {
      if (state === 1) {
        return "Créé";
      }
      if (state === 2) {
        return "En validation";
      }
      if (state === 3) {
        return "Validé";
      }
    };
    const editProject = (id) => {
      if (projects.value.find((e) => e.id === id).state !== 3) {
        router.push({ name: "update-project", params: { projectId: id } });
      } else {
        router.push({ name: "display-project", params: { projectId: id } });
      }
    };
    const tableHeader = ref([
      {
        name: "Nom",
        key: "name",
        sortable: false,
      },
      {
        name: "Etat",
        key: "state",
        sortable: true,
      },
      {
        name: "Editer / Voir",
        key: "edit",
        sortable: false,
      },
      {
        name: "Faire valider",
        key: "validate",
        sortable: false,
      },
      {
        name: "Inviter",
        key: "invite",
        sortable: false,
      },
      {
        name: "Télécharger",
        key: "download",
        sortable: false,
      },
      {
        name: "Supprimer",
        key: "delete",
        sortable: true,
      },
      {
        name: "Utilisateurs",
        key: "users",
        sortable: true,
      },
      {
        name: "Diagnostics",
        key: "diagnostics",
        sortable: true,
      },
    ]);
    const validateProject = (project) => {
      Swal.fire({
        title: `Mise en validation de votre projet ${project.name}`,
        icon: "info",
        html:
          "En cliquant sur 'Soumettre', votre projet sera soumis aux cofondateurs AgriBEST pour validation. Vous serez " +
          "averti par mail lorsque votre projet sera validé et pourrez ensuite inviter des agriculteurs à le rejoindre.",
        confirmButtonText: "Soumettre",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          project.state = 2;
          store
            .dispatch(Actions.UPDATE_PRESCRIBER_PROJECT, project)
            .then(() => {
              store.dispatch(Actions.FETCH_PRESCRIBER_PROJECTS).then(() => {
                reloadTable.value += 1;
              });
              notify({
                color: "success",
                time: 3000,
                text: "Projet soumis pour validation",
              });
            });
        }
      });
    };
    const deleteProject = (project) => {
      Swal.fire({
        title: `Suppression de votre projet ${project.name}`,
        icon: "info",
        html:
          project.state < 3
            ? "Etes vous sûr.e de vouloir supprimer ce projet ?"
            : "<p>Etes vous sûr.e de vouloir archiver ce projet ?</p>" +
              "<i class='bi bi-exclamation-triangle text-danger fs-2'></i> " +
              "<span>Attention ce projet est validé et peut avoir des utilisateurs rattachés. " +
              "La suppression du projet vous empêchera d'accéder aux données associées</span>",
        confirmButtonText: "Supprimer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (project.state !== 3) {
            store
              .dispatch(Actions.DELETE_PRESCRIBER_PROJECT, project.id)
              .then(() => {
                store.dispatch(Actions.FETCH_PRESCRIBER_PROJECTS).then(() => {
                  reloadTable.value += 1;
                });
                notify({
                  color: "success",
                  time: 3000,
                  text: "Projet supprimé",
                });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_PRESCRIBER_PROJECT, {
                id: project.id,
                state: 4,
              })
              .then(() => {
                store.dispatch(Actions.FETCH_PRESCRIBER_PROJECTS).then(() => {
                  reloadTable.value += 1;
                });
                notify({
                  color: "success",
                  time: 3000,
                  text: "Projet supprimé",
                });
              });
          }
        }
      });
    };
    const downloadProject = (project) => {
      store.dispatch(Actions.SEND_MAIL_DB_DOWNLOAD_CSV_PRESCRIBERS, project);
    };
    const inviteToProject = (project) => {
      const body = `Nous avons le plaisir de vous inviter à rejoindre le projet : ${
        project.name
      }. \n \n Le projet à pour objectif de : ${getPurposes(
        project.project_purposes
      )}  \n \n Si vous souhaitez utiliser l'application AgriBEST, téléchargez la sur le store (Apple ou Android) de votre choix puis suivez ce lien : ${
        process.env.VUE_APP_API_URL
      }/project_linking/?project_id=${
        project.uuid
      }&redirect_uri=fr.agri.best:accueil/ \n\n  Pour utiliser rejoindre le projet sur navigateur, suivez ce lien : ${
        process.env.VUE_APP_API_URL
      }/project_linking/?project_id=${project.uuid}&redirect_uri=${
        process.env.VUE_APP_API_URL.split("api")[0]
      } \n\n
      Si vous le souhaitez, vous pouvez visionner le tutoriel d'utilisation de l'application via ce lien : https://www.youtube.com/watch?v=X9CdXj2h57g
      `;
      navigator.clipboard.writeText(body).then(() => {
        notify({
          text: "Votre logiciel de gestion de mail devrait s'ouvrir au clic sur 'inviter'. Sinon le contenu du mail a été copié dans votre presse papier. Vous pouvez le coller (CRTL+V) dans le corps de votre email",
          color: "success",
          duration: 3000,
        });
      });
      Swal.fire({
        title: `Mise en validation de votre projet ${project.name}`,
        icon: "info",
        html:
          "<p>En cliquant sur 'Inviter', un mail s'ouvrira contenant les liens d'invitation pour lier les " +
          "utilisateurs AgriBEST à votre projet. Vous pouvez modifier le corps de ce mail, ainsi que partager les " +
          "liens sur d'autres medias (listes de diffusions, intranet...). Dans le cas où le mail ne s'ouvre pas," +
          "son contenu sera copié dans votre presse papier. Vous pourrez le coller dans le corps d'un nouveau mail (CRTL + V) <br> " +
          "<i class='bi bi-exclamation-triangle text-danger fs-2'></i> " +
          "<span>Attention à ne pas modifier les liens</span>" +
          "</p>",
        confirmButtonText: "Inviter",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const subject = `Invitation au projet : ${project.name}`;
          let url = "mailto:";
          url =
            url +
            "?subject=" +
            encodeURIComponent(subject) +
            "&body=" +
            encodeURIComponent(body);
          openLinkAgriBest(url);
        }
      });
    };
    const getPurposes = (purposes: Array<number>) => {
      let purposeDescriptions = [] as Array<string>;
      for (const purpose of purposes) {
        purposeDescriptions.push(
          projectPurposes.value.find((e) => {
            return e.number === purpose;
          }).description
        );
      }
      return purposeDescriptions.join(", ");
    };
    onMounted(() => {
      store.dispatch(Actions.FETCH_PRESCRIBER_PROJECTS).then(() => {
        reloadTable.value += 1;
      });
    });
    return {
      deleteProject,
      reloadTable,
      downloadProject,
      editProject,
      getState,
      inviteToProject,
      prescriber,
      projects,
      tableHeader,
      validateProject,
    };
  },
});
